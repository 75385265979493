import NextLink, { type LinkProps } from "next/link";
import type React from "react";

import classNames from "classnames";

export default function Link({
  children,
  className,
  ...rest
}: { children?: React.ReactNode } & LinkProps &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps>) {
  return (
    <NextLink
      {...rest}
      className={classNames(
        className,
        "text-blue-500 hover:text-blue-800 cursor-pointer",
      )}
    >
      {children}
    </NextLink>
  );
}

// useful to match styling for things like mailto links
export const A = ({
  children,
  className,
  href,
  ...rest
}: { children?: React.ReactNode } & LinkProps &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps>) => {
  return (
    <a
      {...rest}
      href={href.toString()}
      className={classNames(
        className,
        "text-blue-500 hover:text-blue-800 cursor-pointer",
      )}
    >
      {children}
    </a>
  );
};
