"use client";

import { useRouter, useSearchParams } from "next/navigation";

import queryStringify from "qs-stringify";

import { createAuthChallenge } from "@/utils/api/client";
import { ApiError } from "@/utils/api/fetch";
import { useForm } from "@/utils/useForm";
import { useRequest } from "@/utils/useRequest";

import { H2 } from "@/components/Headings";
import Message from "@/components/Message";
import { Button, Submit } from "@/components/forms/Button";
import Input from "@/components/forms/Input";

import Link from "@/components/Link";
import {
  countryDetails,
  getPlaceholder,
} from "@/hl-common/types/CountryDetails";
import type { API } from "@/hl-common/types/api/API";
import type { CountryCode } from "@/hl-common/types/api/CountryCode";
import { auth_channel } from "@/hl-common/types/api/PrismaEnums";
import type { Dispatch, SetStateAction } from "react";
import { CountrySelect } from "./CountrySelect";
import { useCookiesEnabled } from "./useCookiesEnabled";

export default function LoginForm({
  defaultCountryCode,
}: {
  defaultCountryCode: CountryCode;
}) {
  const cookiesEnabled = useCookiesEnabled();
  const params = useSearchParams();
  const redirectUrl = params.get("redirectUrl") || "/courses";

  const { push, replace } = useRouter();

  const { loading, error, run } = useRequest(createAuthChallenge);

  const { fields, handleInputChange, handleSubmit, setFields } =
    useForm<API.createAuthChallenge.body>(
      {
        countryCode: defaultCountryCode,
        authChannel: auth_channel.WHATSAPP,
        phone: "",
      },
      (body) => {
        run(
          createAuthChallenge({ body }),
          ({ data: { formattedPhone } }) => {
            const query = queryStringify({
              phone: formattedPhone,
              redirectUrl,
              authChannel: body.authChannel,
            });
            push(`/otp?${query}`);

            return true;
          },
          (error: unknown) => {
            if (error instanceof ApiError && error.statusCode === 409) {
              replace(redirectUrl);
            }
          },
        );
      },
    );

  const { authChannel, countryCode } = fields;

  if (!cookiesEnabled) {
    return (
      <Message warning className="text-center">
        Your browser must have{" "}
        <Link
          target="_blank"
          href="https://www.whatismybrowser.com/guides/how-to-enable-cookies"
        >
          cookies enabled
        </Link>{" "}
        to use HealthLearn.
      </Message>
    );
  }

  return (
    <div className="text-center mx-auto">
      <H2 className="mb-8">
        Log In <span className="font-normal">/</span> Register
      </H2>
      <form name="login" onSubmit={handleSubmit}>
        <CountrySelect
          name="countryCode"
          value={fields.countryCode}
          authChannel={authChannel}
          handleInputChange={handleInputChange}
        />

        <Input
          id="phone"
          name="phone"
          required
          autoFocus
          className="mx-auto text-center"
          type="tel"
          value={fields.phone}
          label={
            authChannel === auth_channel.SMS
              ? "Phone Number"
              : "WhatsApp Number"
          }
          placeholder={getPlaceholder(fields.countryCode)}
          onChange={handleInputChange}
        />

        {error && <Message error>{error}</Message>}

        <Submit className="mb-4" loading={loading} />

        <SwitchChannel
          authChannel={authChannel}
          countryCode={countryCode}
          setFields={setFields}
        />
      </form>
    </div>
  );
}

const SwitchChannel = ({
  authChannel,
  countryCode,
  setFields,
}: {
  authChannel: auth_channel;
  countryCode: CountryCode;
  setFields: Dispatch<SetStateAction<API.createAuthChallenge.body>>;
}) => {
  if (
    authChannel === auth_channel.WHATSAPP &&
    countryDetails[countryCode].smsEnabled
  ) {
    return (
      <Button
        className="mb-4"
        minimal
        onClick={(e) => {
          e.preventDefault();
          setFields((fields) => ({
            ...fields,
            authChannel: auth_channel.SMS,
          }));
        }}
      >
        Switch to SMS
      </Button>
    );
  }

  if (authChannel === auth_channel.SMS) {
    return (
      <Button
        className="mb-4"
        minimal
        onClick={(e) => {
          e.preventDefault();
          setFields((fields) => ({
            ...fields,
            authChannel: auth_channel.WHATSAPP,
          }));
        }}
      >
        Switch to WhatsApp
      </Button>
    );
  }
};
