import type React from "react";

import classNames from "classnames";

import { Field } from "./Field";

// warning: don't use the *selected* attribute in child options if the parent select has a value or defaultValue set!

export default function Select({
  className,
  label,
  description,
  shrink,
  ...rest
}: React.SelectHTMLAttributes<HTMLSelectElement> & {
  label?: string;
  description?: string;
  shrink?: boolean;
}) {
  return (
    <Field htmlFor={rest.id} label={label} description={description}>
      <select
        className={classNames(
          className,
          { "w-full md:w-auto": !shrink },
          { "mb-4": !shrink && !className?.includes("mb-") && !description },
          { "mb-1": !shrink && !className?.includes("mb-") && description },
          "block px-3 py-2.5 rounded bg-[#F0F0F0] border-[#DFE2E4] border shadow-inner", // default classes
        )}
        {...rest}
      />
    </Field>
  );
}
