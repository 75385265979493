import classNames from "classnames";

import BasicPage from "./BasicPage";

export default function Loader({
  centered,
  inline,
  small,
}: {
  centered?: boolean;
  inline?: boolean;
  small?: boolean;
}) {
  return (
    <div
      className={classNames({
        "flex-grow flex justify-center items-center": centered,
        "inline-block": inline,
      })}
    >
      <LoaderInner small={small} />
    </div>
  );
}

const LoaderInner = ({ small }: { small?: boolean }) => (
  <div
    className={classNames(
      "animate-spin rounded-full border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]",
      {
        "h-8 w-8 border-4": !small,
        "h-4 w-4 border-2": small,
      },
    )}
    // biome-ignore lint/a11y/useSemanticElements: <div> is acceptable for a loading spinner
    role="status"
  >
    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
      Loading...
    </span>
  </div>
);

export const FullScreenLoader = () => (
  <BasicPage>
    <Loader centered />
  </BasicPage>
);
